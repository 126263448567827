import React from "react"

import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"



const LSExperimental = () => {
  //javascript
  const title = 'Bearings';
  const metatitle = 'Bearings';
  const description = 'Meta Description for Bearings';
  const metadescription = description;
  const style2 = {
    marginBottom: 80
  }
  return(
    <Layout bodyclass="ls-experimental" title={title}>
      <SEO
        title={metatitle}
        description={metadescription}
      />
      <nav className="anchornav">
        <ul>
          <li>
            <a className="more" href="elastomeric-bearings">Elastomeric Bearings</a>
          </li>
          <li>
            <a className="more" href="lead-rubber-bearings">Lead Rubber Bearings (LRB)</a>
          </li>
          <li>
            <a className="more" href="elastomeric-bearings-ptfe">Elastomeric Bearings with PTFE Sheet</a>
          </li>
          <li>
            <a className="more" href="steel-bearings">Steel Bearings</a>
          </li>
        </ul>
      </nav>
      
      
    </Layout>
  )
}

export default LSExperimental
